.profile-img-container {
  position: relative;
  width: 54px; /* Adjusted width and height to match the existing image size */
  height: 54px;
  overflow: hidden;
  border-radius: 50%;
}

.profile-img-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: opacity 0.3s;
}

.profile-img-container:hover img {
  opacity: 0.3;
}

.profile-img-container .icon-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  border-radius: 50%;
  padding: 5px;
  display: none;
  transition: display 0.3s;
}

.profile-img-container:hover .icon-wrapper {
  display: block;
}

.profile-img-container input[type="file"] {
  display: none;
}

.profile-img-container label {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}
