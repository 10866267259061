.file-input__input {
  width: 100px;
  height: 50px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #4245a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.Filelisting {
  display: block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.Filelisting li {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  color: #020073;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
}



.image-card-2 img {
  border: 1px solid #D2D2D2;
  border-radius: 15px;
  height: 445px;
  object-fit: contain;
  object-position: center;
  background-color: #fff;
}



html.semi-dark .sidebar-wrapper .metismenu li .active {
  color: #020073;
  text-decoration: none;
  background: #fff;
  border-left: 4px solid #ffffff;
}


.form-control::placeholder {
  color: #000 !important;
  opacity: 1;
}

.login__field_modal {
  color: #000;
}


/* ------------aayush-css--------------- */

.image-card img {
  border: 1px solid #D2D2D2;
  border-radius: 15px;
  height: 368px;
  object-fit: contain;
  object-position: center;
  background-color: #fff;
}

.titile-number {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  margin-top: 15px;
}

.serial-number {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #020073;
}

.time-stamp {
  font-family: Inter;
  font-size: 19px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #4E4E4E;

}

.titile-dis {
  font-family: Inter;
  font-size: 19px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 15px 0 50px;
}

.custom-tab li {
  width: 272px;
}

.custom-tab li a div {
  justify-content: center;
}

.custom-tab li a div .tab-icon {
  margin-right: 5px;
}

.custom-tab li a div .tab-title {
  color: #020073;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.custom-tab li a div .tab-icon img {
  width: 16px;
}

.custom-tab.nav-tabs .nav-link.active {
  border-top: 5px solid #020073;
  border-left: none;
  border-right: none;
  background-color: #fff;
}

.custom-tab li {
  width: 164px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 15px;
}

.gallery {
  background-color: #F7F9FD
}

.nav-primary.nav-tabs .nav-link {
  display: flex;
  justify-content: center;

}

.custom-tab.nav-tabs .nav-link {
  width: 164px;
  height: 48px;
  flex-shrink: 0;
  background-color: #E4EEFC;
}

.model-text {
  font-family: Inter;
  font-size: 27px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  color: #000;
}

.cross {
  background: #E3E2FA url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAhCAYAAAAlK6DZAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMYSURBVHgBtZetUxtBGMaf3UMgT+IaWUlUD5gpwSGLqyuoTl0gw9CqUtlhgKurA/4CdFVAlIsjdaB6krrDVXC3fd/7yN5HktsL6TPDbHaz2d9+vM/ui4VY+8sSzoHC63vgZ4D/oq4NOIvA4K/gqkTvNxUt+vMjLGwAX33MVQctiac+MyKEGzJttdOyJfB0ibmqa2dArknI9RgaAVtZF1o6bXXvDHMRA60RMGFFF1by0fMVVh4FxGb63bKAIxQGV3iGJNa+U7GpgdgGTq8t3cUbCKzQNgsnqYuOwqpP7b8wg2i3XCrea6A6BE6+xSOXOwv0LqnxTVoNIkgKrKMhmgEPqfhcAn7R35ekEO4oIIPQmUSXHH2YE5Alxv+0S1Es+3TGrbSBrBS2AXeqhy3sbSuIMw3EOXC8U53YWLkUWBZHdAahSVg1VtpfzgOT3aoCk8lN1M0DRfQfWm18viIGr9kKNz+qfdn8oUcfFhOgoklHK3z7NISyvKHCGgM7aYNDEf6oKNKLwNj8SzkgBZ/7MGnUGijr5rpkpU2yEtnIu8tfbyWgP21EAUORlfq5FQcWWSmMIzsDIlBJsPn1Yxmra1NE3+YiuiDyc9vUzxLGcoNk6xBUgXy9mV8gBmea1yCgCOaAcbKW1Bof0ECNoOlt8zHfRuez1PRxMIaWrzda4ZUYPVmiU7XStLGMZra3jcJ9inOFYz7fC91LnPKtZDKeQfRy/hTdZjU+QwK2kxpHtMVWymD0Ki2069KdmpWy+aO+BrL5ww39PUd0uMXtaUOamnTtGaGj28bWwPi2KVmm+eNg1QBbJaA/vj8/Dqv3tM1vuTb9cRgL5WQKHgrXG78Yddebd1fKs5xJVqpAKZli4MusTkAaxDXMk8zyLFms9M6hI3GUvaGBIpzssodzALdsJZkDHlLxTv+Yc5vjC8ygakQX8yyZA05NppopeRxy4PQ/h8RKcgG9TgnoPg84AhesJOIE3opzKEmzaWkgZ28nu5ibjoa0iNF4ST7de2EpvPIpzPm5uqIt+TQpmZpdOs9Ktnseu2is/fXsTP8Bib1Qu81Oj1QAAAAASUVORK5CYII=") center/1em auto no-repeat;
  width: 31px;
  height: 30px;
}

.download img {
  width: 46px;
}

.download {
  margin-right: 15px;
}

.doller {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-right: 15px;
}

.price-value {
  font-family: Inter;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}

.price {
  display: flex;
}

.price-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #808080;
  margin-top: 20px;
}

.custom-tab-squre {
  background-color: #E4EEFC;
  padding: 5px;
  border-radius: 5px;
}

.custom-tab-squre li {
  margin-right: 15px;
}

.custom-tab-squre li a {
  color: #020073 !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.custom-tab-squre li .active {
  background-color: #020073 !important;
  color: #fff !important;
}

.scrollbar-1 {
  scrollbar-width: thin;
  scrollbar-color: #020073 #E4E4E4;
}

.scrollbar-1::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar-1::-webkit-scrollbar-track {
  background-clip: content-box;
  border: 2px solid transparent;
}

.scrollbar-1::-webkit-scrollbar-thumb {
  background-color: #020073;
  border-radius: 5px;
}

.scrollbar-1::-webkit-scrollbar-thumb:hover {
  background-color: #020073;
}

.scrollbar-1::-webkit-scrollbar-corner,
.scrollbar-1::-webkit-scrollbar-track {
  background-color: #b0b7c4;
}

.scrollbar-1 {
  height: 284px;
  overflow-y: scroll;
}

.custom-tab-2 li a div .tab-icon img {
  width: 20px;
  height: 20px;
  margin-right: 15px
}

.custom-tab-2 li a div .tab-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #020073;
}

.custom-tab-2 li {
  background-color: #E4EEFC;
  margin-right: 15px;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}

.custom-tab-2 {
  border-bottom: none;
}

.custom-tab-2.nav-tabs .nav-link.active {
  border-top: 5px solid #020073;
  border-left: none;
  border-right: none;
  background-color: #F7F9FD;
}

.custom-tab-2-content {
  background-color: #F7F9FD;
  padding: 15px;
}

.verticle-tab {
  display: flex;
}

.verticle-tab .custom-tab-2 {
  display: block;
}

.verticle-tab .custom-tab-2.nav-tabs .nav-link.active {
  border-top: none;
  border-left: 5px solid #020073;
  border-right: none;
  background-color: #fff;
}

.verticle-tab .custom-tab-2 li {
  background-color: #E4EEFC;
  margin-bottom: 15px;
  margin-right: 0;
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}

.verticle-tab .nav-primary.nav-tabs .nav-link {
  display: flex;
  justify-content: start;
  height: 60px;
}

.verticle-tab .tab-content {
  width: 100%;
  background-color: #fff;
}

.verticle-tab {
  background-color: #F7F9FD;
  padding: 15px;
}

.fileName p {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.file-table .table-secondary {
  --bs-table-bg: #fff !important;
}

.file-table .table-secondary tr th {
  border-right: 1px solid #CFCFCF;
  height: 40px;
}

.file-table .table-secondary tr th:last-child {
  border-right: none;
}

.file-table tbody {
  height: 50px !important;
}

.image-card {
  cursor: pointer;
}

.ck.ck-content {
  height: 150px;
}

.download {
  cursor: pointer;
}

.shippedDocList div .modal-content {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.modal-dialog.modal-dialog-centered {
  width: 900px;
  max-width: 600px;
}

span.nct-dropdown {
  text-align: right;
  position: absolute;
  right: 10px;
}

.side-nav-link.collapsed .nct-dropdown .fa.fa-angle-down {
  transform: rotate(-90deg);
}

#sidebarSetting ul li {
  border-bottom: none;
}