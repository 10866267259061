.image-card img {
    border: 1px solid #D2D2D2;
    border-radius: 15px;
    height: 183px;
    object-fit: contain;
    object-position: center;
    background-color: #fff;
 }

 .serial-number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #020073;
 }

 .time-stamp {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #4E4E4E;
 }

 .titile-dis {
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    margin: 15px 0 50px;
 }

 .image-card-2 img {
    border: 1px solid #D2D2D2;
    border-radius: 15px;
    height: 445px;
    object-fit: contain;
    object-position: center;
    background-color: #fff;
 }

 .oxygen h2 {
    font-size: 45px;
    font-weight: 600;
 }

 .nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
    background-color: #020073;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
 }

 .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    color: #020073;
    border-right: 1px solid #BBD7FE;
    padding: 7px;
    font-size: 12px;
 }
 .nav-link-2{                        
  border-right: 1px solid #bbd7fe00 !important;                      
 }    
 .titile-number {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    margin-top: 15px;
}        
.model-text {
   font-family: Inter;
   font-size: 27px;
   font-weight: 600;
   line-height: 33px;
   letter-spacing: 0em;
   color: #000;
}
.doller {
   font-family: Inter;
   font-size: 20px;
   font-weight: 500;
   line-height: 24px;
   letter-spacing: 0em;
   text-align: left;
   color: #000;
   margin-right: 15px;
}
.price-value {
   font-family: Inter;
   font-size: 36px;
   font-weight: 600;
   line-height: 44px;
   letter-spacing: 0em;
   text-align: left;
   color: #000;
}
.price-title {
   font-family: Inter;
   font-size: 16px;
   font-weight: 500;
   line-height: 19px;
   letter-spacing: 0em;
   text-align: left;
   color: #808080;
   margin-top: 20px;
}
.download {
   margin-right: 5px;
}
.download img {
   width: 46px;
}
.custom-tab-2-content {
   background-color: #F7F9FD;
   padding: 15px;
}
.verticle-tab {
   background-color: #F7F9FD;
   padding: 15px;
}
.scrollbar-1 {
   height: 284px;
   overflow-y: scroll;
}
.verticle-tab {
   display: flex;
}
.verticle-tab .tab-content {
   width: 100%;
   margin: 0px;
   background-color: #fff;
}
button.btn-close {
   background-color: #E3E2FA;
   width: 30px;
   height: 30px;
}
.gallery {
   background-color: #F7F9FD;
}
.option_heading{
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: #02007380;
  margin-bottom: 1px;
}
.option_child{
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
   padding-left: 5px;
}
.MuiTabs-indicator {
   display: none !important;
 }