.otp-field {
    display: flex;
}

.otp-field input {
    width: 100%;
    font-size: 32px;
    padding: 10px;
    text-align: center;
    margin: 2px;
    border: 2px solid #fff;
    background: #020073;
    font-weight: bold;
    color: #fff;
    outline: none;
    transition: all 0.1s;
}

.otp-field input:focus {
    border: 2px solid #ffffff;
}

.disabled {
    opacity: 0.5;
}

.space {
    margin-right: 1rem !important;
}

.login__field-group.mt-3 {
    position: relative;
  }
  .passwordHideShow {
    position: absolute;
    top: 35px;
    right: 15px;
    border: none;
    background: transparent;
    color: #fff;
    font-size: 25px;
  }