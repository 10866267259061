.history-tl-container {
  font-family: "Inter";
  width: 100%;
  margin: auto;
  display: block;
  position: relative;
}

.history-tl-container ul.tl {
  /* margin:20px 0 0 84px; */
  padding: 0;
  display: inline-block;
  width: 300px;
  position: relative;
  left: 145px;

}

.history-tl-container ul.tl li {
  list-style: none;
  margin: auto;
  /* margin-left:200px; */
  min-height: 50px;
  /*background: rgba(255,255,0,0.1);*/
  border-left: 1px dashed #020073;
  padding: 0 0 50px 30px;
  position: relative;
}

.history-tl-container ul.tl li:last-child {
  border-left: 0;
}

.history-tl-container ul.tl li::before {
  position: absolute;
  left: -10px;
  top: 0;
  content: " ";
  border: 8px solid #020073;
  border-radius: 500%;
  background: #fff;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;

}

.history-tl-container ul.tl li:hover::before {
  border-color: #258CC7;
  transition: all 1000ms ease-in-out;
}

ul.tl li .item-title {}

ul.tl li .item-detail {
  color: #000;
  font-size: 12px;
}

ul.tl li .timestamp {
  color: #000;
  position: absolute;
  width: 120px;
  left: -50%;
  text-align: right;
  font-size: 12px;
  font-weight: bold;
}

.item-title {
  color: #000;
  font-weight: bold;
}

/*--------------------------------Vertical------------------------------------------*/

.history-tl-container ul.tl.virtical {
  padding: 0;
  display: flex;
  width: 100%;
  position: relative;
  left: 0;
  width: 100%;
  overflow-x: scroll;
  padding: 0 15px;
  scrollbar-width: thin;
  scrollbar-color: #020073 #ddd;
  -webkit-scrollbar-track: #000
}

.history-tl-container ul.tl.virtical li {
  list-style: none;
  min-height: 50px;
  border-top: 1px dashed #020073;
  padding: 5px 0 0 15px;
  position: relative;
  margin-top: 72px;
  min-width: 150px;
  max-width: 200px;
  border-left: none;
  margin-left: 0;
  margin-right: 0;
}

.history-tl-container ul.tl.virtical li:last-child {
  border-top: 0;
}

ul.tl.virtical li .timestamp {
  color: #000;
  position: absolute;
  width: 120px;
  top: -50%;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  left: 15px;
}

.history-tl-container ul.tl.virtical li::before {
  position: absolute;
  left: -10px;
  top: -12px;
  content: " ";
  border: 8px solid #020073;
  border-radius: 500%;
  background: #fff;
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

ul.tl.virtical li .item-detail {
  height: 60px;
}

.borderLine {
  border-right: 1px solid rgba(224, 224, 224, 1);
  padding: 0 5px;
  margin-right: 5px;
}

.borderLine:last-child {
  border: none;
}